import { useContext, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { CloseRounded } from '@mui/icons-material';

const PublishBlogModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [blogDate, setBlogDate] = useState(dayjs());
  const [updatingStatusId, setUpdatingStatusId] = useState();

  const handleCloseModal = () => {
    if (modalDetails.onClose) {
      modalDetails.onClose(); // Call the callback function
    }
    modalCtx.closeModal(); // Close the modal
  };

  const deleteBlog = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + id, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting blog...");
        }
      }).catch((err) => {
        console.error("Error while deleting blog...", err);
      });
    }
  }

  const updateDate = (blogId) => {
    if (authCtx.isLoggedIn) {
        fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + blogId, {
          method: 'DELETE',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            // modalCtx.closeModal();
            console.log("update date");
            
          } else {
            console.error("Error while deleting blog...");
          }
        }).catch((err) => {
          console.error("Error while deleting blog...", err);
        });
      }

  }
  const handelDateChange = (date) => {
    setBlogDate(date);
    console.log(date);
  }

  const updateStatus = async (blogId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId()
    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status,blogDate })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {

            setUpdatingStatus(false)
            modalCtx.closeModal();

          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }


  if (modalDetails.blog.status == "draft") {
    return (
      <div className="publish-blog-modal">
        <div className='heading'>
          <h3>Are you sure that you wish to publish this blog ?</h3>
          <CloseRounded className='close' onClick={() => handleCloseModal()} />
        </div>
        <p>Choose Date</p>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={blogDate} onChange={handelDateChange} />
          </LocalizationProvider>
        </div>
        <div className="footer">

          <button className="btn-primary-border" variant='outlined' onClick={(e) => { handleCloseModal() }}>Cancel</button>
          <button className="btn-primary" onClick={() => updateStatus(modalDetails.blog.id, modalDetails.blog.status)}>{updatingStatus ? <CircularProgress size={24} style={{color: '#fff'}} ></CircularProgress> : 'Publish'}</button>
        </div>
      </div>
    )
  } else {
    return <div className="publish-blog-modal">
      <div className='heading'>
        <h3>Are you sure that you wish to draft this blog ?</h3>
        <CloseRounded className='close' onClick={() => handleCloseModal()} />
      </div>

      <div className="footer">

        <button className="btn-primary-border" variant='outlined' onClick={(e) => { handleCloseModal() }}>Cancel</button>
        <button className="btn-primary" onClick={() => updateStatus(modalDetails.blog.id, modalDetails.blog.status)}>{updatingStatus ? <CircularProgress size={24} style={{color: '#fff'}} ></CircularProgress> : 'Draft'}</button>
        {/* <button className="confirm-btn" onClick={() => updateDate(modalDetails.blog.id)}>Draft</button> */}
      </div>
    </div>
  }
}

export default PublishBlogModal;

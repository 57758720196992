import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

const FilterCouponByCategoryModal = ({ modalDetails }) => {
  const { handleDisplayedData } = modalDetails; 
  const data = modalDetails.data; 
  // console.log(data.categories);
  
  
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const closeHandle = () => {
      modalCtx.closeModal();
  }

  const [expandedCategories, setExpandedCategories] = useState({});
  const [categories, setCategories] = useState(data?.categories?.length > 0 ? data.categories : []);
  const [checkedCategories, setCheckedCategories] = useState({});
  
  const handleFilter = () => {
    const allIDs = Object.keys(checkedCategories)
    .filter((id) => checkedCategories[id]) 
    .map((id) => parseInt(id, 10)); 

    console.log("Extracted IDs:", allIDs);
    
    if (handleDisplayedData) {
      handleDisplayedData(allIDs);
    }
  
    modalCtx.closeModal();
  };
  
    const handleCheckboxChange = (id, subcategories = []) => {
      setCheckedCategories((prev) => {
        const isChecked = !prev[id];
        const updated = { ...prev, [id]: isChecked };
  
        // If parent is checked/unchecked, update its children
        if (subcategories.length > 0) {
          subcategories.forEach((sub) => {
            updated[sub.id] = isChecked;
          });
        }
  
        // If a subcategory is unchecked, uncheck the parent
        const parent = categories.find((cat) =>
          cat.subcategories?.some((sub) => sub.id === id)
        );
        if (parent && !isChecked) {
          updated[parent.id] = false;
        }
  
        // If all subcategories are checked, check the parent
        if (parent) {
          const allChecked = parent.subcategories.every((sub) => updated[sub.id]);
          updated[parent.id] = allChecked;
        }
  
        return updated;
      });
    };
  
  //Search Field
  const [searchQuery, setSearchQuery] = useState("");

  const filteredCategories = categories.map((category) => {
    const categoryMatch = category.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchingSubcategories = category.subcategories?.filter((subcategory) =>
      subcategory.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (categoryMatch || (matchingSubcategories && matchingSubcategories.length > 0)) {
      return {
        ...category,
        subcategories: matchingSubcategories || [],
      };
    }
    return null;
  }).filter(Boolean); 

  const handleToggleExpand = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId], 
    }));
  };

  const handleParentCheckboxChange = (categoryId, subcategories) => {
    const isChecked = !checkedCategories[categoryId];
    subcategories.forEach((subcategory) => {
      handleCheckboxChange(subcategory.id, isChecked);
    });
    handleCheckboxChange(categoryId, isChecked);
  };

  const handleSubcategoryCheckboxChange = (subcategoryId) => {
    handleCheckboxChange(subcategoryId, !checkedCategories[subcategoryId]);
  };

  return (
   <div className="create-devotional-container">
      <div className='devotional-header'>
                  <h1>Filter by Category</h1>
                </div>
      <div className='filter-by-category-body'>
      {/* Search Bar */}
      <TextField
              placeholder="Search a category"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#281de4" }} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              fullWidth
              style={{marginBottom: "25px"}}
            />

            {/* Category List */}
            <FormGroup>
        {filteredCategories.map((category) => (
          <div key={category.id} style={{ marginBottom: "1rem" }}>
            {/* Parent Category */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#1c2238",
                      "&.Mui-checked": {
                        color: "#1c2238",
                      },
                    }}
                    checked={!!checkedCategories[category.id]}
                    onChange={() =>
                      handleCheckboxChange(category.id, category.subcategories || [])
                    }
                  />
                }
                label={category.title}
              />
              {category.subcategories?.length > 0 && (
                <IconButton
                  size="small"
                  onClick={() => handleToggleExpand(category.id)} // Toggle expand/collapse
                >
                  {expandedCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </div>

            {/* Subcategories */}
            {category.subcategories?.length > 0 && (
              <Collapse in={expandedCategories[category.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ paddingLeft: "2rem" }}>
                  {category.subcategories.map((subcategory) => (
                    <ListItem key={subcategory.id} style={{ padding: 0 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#1c2238",
                              "&.Mui-checked": {
                                color: "#1c2238",
                              },
                            }}
                            checked={!!checkedCategories[subcategory.id]}
                            onChange={() => handleCheckboxChange(subcategory.id)}
                          />
                        }
                        label={subcategory.title}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </FormGroup>
                </div>
                <div className="footer-devotional">
                <Button style={{ color: "#555" }} onClick={() => closeHandle()}>Close</Button>
        
                <Button
                  style={{ color: "#555" }}
                  onClick={handleFilter}
                  className={Object.keys(checkedCategories).some(id => checkedCategories[id]) ? "" : "disabled"}
                >
                  Filter
                </Button>
                </div>
              </div>          
  )
}

export default FilterCouponByCategoryModal
import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

const DeleteLocationModal = ({ modalDetails }) => {

    console.log(modalDetails?.location);
    
    const data = modalDetails?.location;
    const companyID = data?.companyId;
    const locationID = data?.id;
    


  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const closeHandle = () => {
    modalCtx.closeModal();
  }
    
  const deleteLocation = async () => {
    console.log(companyID,locationID);
    
    const url = `${process.env.REACT_APP_API_URI}/v3/admin/company/${companyID}/location/${locationID}`;
  
    try {
      const response = await fetch(url, {
        method: 'DELETE', 
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      });
  
      if (response.ok) {
        console.log('Location deleted successfully');
        modalCtx.closeModal();
      } else {
        const errorData = await response.json();
        console.error('Error deleting location:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };
  

  return (
    <>
      <div className="create-devotional-container">
        <div className='devotional-header'>
          <h1>Delete Location</h1>
          {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
        </div>
        <div className=" body-container create-container" style={{textAlign:"center"}}>
          <span>Are you sure you want to delete this Location ?</span>
        </div>
        <div className="footer-devotional">
        <Button style={{color:"#555"}} onClick={() => closeHandle()}>No</Button>
        <Button style={{color:"#555"}} onClick={deleteLocation}>Yes</Button>
        </div>
      </div>
    </>
  )
}

export default DeleteLocationModal;

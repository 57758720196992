//react
import React, { useContext, useEffect, useState } from 'react'
import {
  Map,
  useApiIsLoaded,
  useMapsLibrary,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";
import { VariableSizeList, FixedSizeList as List } from 'react-window';
//mui
import { Button, TextField, Switch, FormGroup, FormControlLabel, TableCell, CircularProgress, TableContainer, Table, TableHead, TableRow, TableBody, Tab ,InputAdornment,Snackbar,SnackbarContent} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { CheckCircleOutline } from '@mui/icons-material';
//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
 
 
import { Delete, EditLocation, LocationOn } from '@mui/icons-material';
import { blue } from '@mui/material/colors';


const DefaultLocation = {
  lat: 37.7749,
  lng: -122.4194,
};

const ManageLocationDrawer = (companyId) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState( companyId?.drawerDetails?.locations || [])
  const companyid = companyId?.drawerDetails?.companyDetails?.id;
  const [selectedPosition, setSelectedPosition] = useState({ lat: 28.7041, lng: 77.1025 });
  const [newLocation, setNewLocation] = useState({
    title: "",
    address: "",
    phone: "",
    email: "",
    country: "",
    latitude: "",
    longitude: ""
  })
  const [errorField, setErrorField] = useState({
    title: "",
    address: "",
    phone: "",
    email: "",
  })


  const geocoding = useMapsLibrary("geocoding");
  const isLoaded = useApiIsLoaded();

  useEffect(() => {
    void getAddressPosition();
  }, [newLocation.address, geocoding, isLoaded]);

  async function getAddressPosition() {
    if (isLoaded && geocoding) {
      try {
        const geocoder = new geocoding.Geocoder();
        const response = await geocoder.geocode({ address: newLocation.address });
  
        if (response.results.length === 0) {
          setNewLocation(prev => ({
            ...prev,
            latitude: 0,
            longitude: 0
          }));
          throw new Error("No results found for the specified address.");
        }
        const location = response.results[0].geometry.location;
        setNewLocation(prev => ({
          ...prev,
          latitude: location.lat(),
          longitude: location.lng()
        }));
        console.log("Updated location:", {
          lat: location.lat(),
          lng: location.lng()
        });
      } catch (error) {
        setNewLocation(prev => ({
          ...prev,
          latitude: 0,
          longitude: 0
        }));
        if (error.message.includes("ZERO_RESULTS")) {
          console.error("Geocoding failed: No results found for the specified address.");
        } else {
          console.error("An unexpected error occurred:", error.message);
        }
      }
    }
  }

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackbarContentText, setSnackbarContentText] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const openSnackbar = (option) => {
    if (option === "save") { 
      setOpen(true);
      setSnackbarContentText("Location saved successfully.");
    }
    else if (option === "delete") {
      setOpen(true);
      setSnackbarContentText("Location deleted successfully.");
    }
    else if (option === "add") {
      setOpen(true);
      setSnackbarContentText("New location added successfully.");
    }
    else if (option === "reset") {
      setOpen(true);
      setSnackbarContentText("Location reset successfully.");
    }
    else if (option === "error") {
      setOpen(true);
      setSnackbarContentText("Error occurred while saving or deleting location.");
    }
  };

  
  //Handle Edit Location
  const [editModeOn, setEditModeOn] = useState(false);

  const [initialLocationState, setInitialLocationState] = useState({
    title: "",
    address: "",
    phone: "",
    email: "",
    country: "",
    latitude: "",
    longitude: ""
  });
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [locationId, setLocationId] = useState(null);

  const roundToPrecision = (value, precision = 7) => {
    return parseFloat(value.toFixed(precision));
  };
  
  const compareLocations = (newLocation, initialLocationState) => {
    const roundedNewLocation = {
      ...newLocation,
      latitude: roundToPrecision(newLocation.latitude),
      longitude: roundToPrecision(newLocation.longitude)
    };
  
    const roundedInitialLocationState = {
      ...initialLocationState,
      latitude: roundToPrecision(initialLocationState.latitude),
      longitude: roundToPrecision(initialLocationState.longitude)
    };
  
    return JSON.stringify(roundedNewLocation) !== JSON.stringify(roundedInitialLocationState);
  };

  useEffect(() => {
    if (editModeOn) {
      const isChanged = compareLocations(newLocation, initialLocationState);
      setIsSaveEnabled(isChanged);
    }
  }, [newLocation, editModeOn]);
  

  const handleEdit = (location) => {
    console.log(location);
    setErrorField({
      title: "",
      address: "",
      phone: "",
      email: "",
    }); 
    const lat = location?.latitude;
    const lng = location?.longitude;
    if (lat && lng) {
      setLocationId(location.id);
      setNewLocation(location);
      setInitialLocationState(location);
      setSelectedPosition({ lat, lng });
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "",
      }));
      setEditModeOn(true);
    } else {
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "Address not found.", 
      }));
    }
  };

  const openSaveModal = async () => {
    handleFindAddress(newLocation?.latitude, newLocation?.longitude);
    const validation = checkValidations();
    if (validation) { 
      modalCtx.setDetails("save-company-manage-location", {handleSaveLocation});
      modalCtx.openModal();
    }

  };

  const handleSaveLocation = async () => {
    
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company/${companyid}/location/${locationId}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json', 
          Authorization: `Bearer ${authCtx.token}` 
        },
        body: JSON.stringify({
          title: newLocation.title,
          address: newLocation.address,
          country: newLocation.country,
          email: newLocation.email,
          mobile: newLocation.phone, 
          latitude: newLocation.latitude,
          longitude: newLocation.longitude
        })
      });
  
      const data = await response.json();
      
      if (data.status === "success") {
        openSnackbar("save");
        getAllLocation(); 
        setNewLocation({
          title: "",
          address: "",
          phone: "",
          email: "",
          country: "",
          latitude: "",
          longitude: ""
        });
        setLoading(false);
        setEditModeOn(false);
        setSelectedPosition(userLocation);
      }
    } catch (error) {
      console.log(error);
      console.error('Error saving location:', error);
      setLoading(false);
    }
  };
  

//Finding Address through latitude and longitude
  const handleFindAddress = (lat, lng) => {
    console.log(lat, lng);
  
    if (lat && lng) {
      setSelectedPosition({ lat, lng });
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "",
      })); 
    } else {
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "Address not found.", 
      }));
    }
  };
  

  //Field validation
  const checkValidations = () => {
    const errors = {};

    errors.title = !newLocation?.title?.trim()
      ? "Title cannot be empty."
      : "";

    errors.address = !newLocation?.address?.trim()
    ? "Address cannot be empty."
    : (!newLocation?.latitude && !newLocation?.longitude)
    ? "Address not found."
    : "";

    errors.phone = !newLocation?.phone?.trim()
      ? "Phone number cannot be empty."
      : !/^\d{10}$/.test(newLocation?.phone.trim())
      ? "Phone number must be exactly 10 digits."
      : "";

    errors.email = !newLocation?.email?.trim()
      ? "Email cannot be empty."
      : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newLocation?.email.trim())
      ? "Please enter a valid email address."
      : "";

    setErrorField(errors);

    return Object.values(errors).every((error) => error === "");
  };

  //Adding a new location
  const openAddModal = async () => {
    handleFindAddress(newLocation?.latitude, newLocation?.longitude);
    const validation = checkValidations();
    if (validation) { 
      modalCtx.setDetails('add-company-manage-location', {handleAddNewLocation});
      modalCtx.openModal();
    }

  };

  const handleAddNewLocation = async () => {
    
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company/${companyid}/location`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          Authorization: `Bearer ${authCtx.token}` 
        },
        body: JSON.stringify({
          title: newLocation.title,
          address: newLocation.address,
          country: newLocation.country,
          email: newLocation.email,
          mobile: newLocation.phone, 
          latitude: newLocation.latitude,
          longitude: newLocation.longitude
        })
      });
      console.log(response)
      const data = await response.json();
      if (data.status === "success") {
        openSnackbar("add");
        getAllLocation();
        setNewLocation({
          title: "",
          address: "",
          phone: "",
          email: "",
          country: "",
          latitude: "",
          longitude: ""
        });
        setLoading(false);
        setSelectedPosition(userLocation);
      }
    } catch (error) {
      console.log(error)
      console.error('Error adding location:', error);
    }
  }
  

  //Get all location
  const getAllLocation = () => {
    const URL = `${process.env.REACT_APP_API_URI}/v3/admin/companies/${companyid}/locations`
    fetch(URL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        setLocations(data?.data);
      }
    }).catch(err => {
      console.error(err)
    });
  }




  // Get User Location
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState({ lat: 28.7041, lng: 77.1025 });

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setSelectedPosition({ lat: latitude, lng: longitude });
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (err) => {
          console.error(err);
          setError("Unable to retrieve location. Please allow location access.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  //Get Address
  const getAddress = async (lat, lng) => {
    if (lat && lng) {
      setSelectedPosition({ lat, lng });
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "",
      })); 
    } else {
      setErrorField((prevErrors) => ({
        ...prevErrors,
        address: "Address not found.", 
      }));
    }
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === 'OK' && data.results.length > 0) {
        // Extract the formatted address from the first result
        const fullAddress = data.results[0].formatted_address;
  
        // Extract the portion after the plus code, if needed (for example "Dhandadihi, West Bengal, India")
        const addressParts = fullAddress.split(' ');
        const relevantAddress = addressParts.slice(1).join(' ');  // Remove the first part (J5G6+8F)
        
        console.log('Extracted Address:', relevantAddress);
        setNewLocation((prev) => ({
          ...prev,
          address: relevantAddress,
          latitude: lat,
          longitude: lng
        }));
  
        return relevantAddress;
      } else {
        console.error('No address found for the given coordinates.');
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  //Delete Location Modal
  const openDeleteLocationModal = (location) => {
    modalCtx.setDetails('delete-company-manage-location', {location});
    modalCtx.openModal();
  }

  //Reset Location
  const handleResetLocation = () => {
    openSnackbar("reset");
    setNewLocation({
      title: "",
      address: "",
      phone: "",
      email: "",
      country: "",
      latitude: "",
      longitude: ""
    });
    setErrorField({});
    setSelectedPosition(userLocation);
    setEditModeOn(false);
  }

  //Drawer Context
  const onCloseDrawer = () => { 
    drawerCtx.closeDrawer();
  }

  //Location Table
  const LocationRow = ({ index, style, data }) => {
    const location = data[index];
    const rowHeight = location?.address?.length > 50 ? 50 : 50;

    return (
      <TableRow
      style={{ ...style, display: 'flex', height: rowHeight }}
      className="location-row"
      key={location?.id}
    >
      <TableCell className="location-col-title">{location?.title}</TableCell>
      <TableCell className="location-col-address">
        {location?.address?.length > 20
          ? `${location.address.substring(0, 30)}...`
          : location?.address}
      </TableCell>
      <TableCell className="location-col-coords">{location?.longitude}</TableCell>
      <TableCell className="location-col-coords">{location?.latitude}</TableCell>
      <TableCell className="actions">
          <Button
            style={{justifyContent:"left", minWidth:"0"}}
          size="small"
          onClick={() => handleEdit(location)}
        >
          <EditIcon fontSize="small" />
        </Button>
        <Button
         style={{justifyContent:"left", minWidth:"0"}}
          size="small"
          color="error"
          onClick={() => openDeleteLocationModal(location)}
        >
          <Delete fontSize="small" />
        </Button>
      </TableCell>
    </TableRow>
    
    );
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Manage Locations</h1>
        </header>

        <section style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <div className='locations-textfield'>
            <div className='textfields-container'>
              <TextField id="standard-basic" variant="standard" name="title" label="Location Title" value={newLocation.title} onChange={(e) => setNewLocation((prev) => ({ ...prev, title: e.target.value })) } error={!!errorField.title} helperText={errorField.title || " "} />
              <TextField
                id="standard-basic"
                variant="standard"
                label={newLocation.address ? "Address" : "Search a location"}
                value={newLocation.address}
                onChange={(e) => setNewLocation(prev => ({ ...prev, address: e.target.value }))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        style={{ color: "#281de4", cursor: "pointer" }}
                        onClick={() => handleFindAddress(newLocation?.latitude, newLocation?.longitude)}
                        disabled={!newLocation.address}
                      />
                    </InputAdornment>
                  ),
                }}
                error={errorField.address}
                helperText={errorField.address || " "}
                />
               <TextField
                  id="standard-basic"
                  variant="standard"
                  name="phone"
                  label="Phone"
                  value={newLocation.phone}
                  onChange={(e) =>
                    setNewLocation((prev) => ({ ...prev, phone: e.target.value }))
                  }
                  error={!!errorField.phone}
                  helperText={errorField.phone || " "}
                />
               <TextField
                  id="standard-basic"
                  variant="standard"
                  name="email"
                  label="Email"
                  type="email"
                  value={newLocation.email}
                  onChange={(e) =>
                    setNewLocation((prev) => ({ ...prev, email: e.target.value }))
                  }
                  error={!!errorField.email}
                  helperText={errorField.email || " "}
                />
              </div>
            </div>
            <div className='manage-location-buttons'>
              <Button className='reset-btn' onClick={handleResetLocation}>
                <RestartAltIcon style={{marginRight: "4px"}}/> Reset
              </Button>
              {
                editModeOn ? <Button disabled={!isSaveEnabled} className='location-add-button'  onClick={openSaveModal}>
                  <SaveIcon style={{ marginRight: "4px" }} />Save Changes 
                </Button> : <Button className='location-add-button' onClick={openAddModal}>
                  <AddIcon style={{ marginRight: "4px" }} />Add Location
                </Button>
              }
            </div>

            <div className='map-container'>
              <Map style={{ minHeight: 250 }} center={selectedPosition} defaultZoom={12} mapId={'nhbvasjd8978213clas'} options={{ draggable: true, zoomControl: true, streetViewControl: false, mapTypeControl: true, mapTypeId: 'roadmap', }} >
                <AdvancedMarker
                  position={selectedPosition}
                  draggable={true} 
                  onDragEnd={async(event) => {
                    if (event.latLng) {
                      const newLat = event.latLng.lat();
                      const newLng = event.latLng.lng();
                      setSelectedPosition({ lat: newLat, lng: newLng });
                      const address = await getAddress(newLat, newLng);
                      console.log('Address:', address);
                    }
                  }}
                >
                <Pin />
              </AdvancedMarker>
            </Map>
          </div>

          <div>
            <TableContainer >
              <Table>
                <TableHead >
                <TableRow className="location-row">
                  <TableCell className="location-col-title">Title</TableCell>
                  <TableCell className="location-col-address">Address</TableCell>
                  <TableCell className="location-col-coords">Longitude</TableCell>
                  <TableCell className="location-col-coords">Latitude</TableCell>
                  <TableCell className="actions">Actions</TableCell>
                </TableRow>

                </TableHead>
                </Table>
                <Table >
                <TableBody className='location-tbody'>
                  {locations.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No locations found
                      </TableCell>
                    </TableRow>
                  ) : (
                  <VariableSizeList
                    height={150}
                    itemCount={locations.length}
                    itemSize={(index) => (locations[index]?.address?.length > 50 ? 50 : 50)}
                    itemData={locations}
                  >
                    {LocationRow}
                  </VariableSizeList>
                  )}
                </TableBody>
              </Table>

            </TableContainer>
          </div>
          <div className="double-column-form aligned-left margin-top">
            <Button variant='outlined' className='btn-primary' style={{ marginBottom: "20px" }} onClick={onCloseDrawer}> Close</Button>
            {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
          </div>
        </section>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: 'green',
            borderRadius: '10px',
            color: 'white',
            padding: '12px 20px',
            display: 'flex',
            alignItems: 'center', 
          }}
          message={
            <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutline style={{ marginRight: '8px', fontSize: '20px' }} />
              {snackbarContentText}
            </span>
          }
        />
      </Snackbar>
    </div>
  )
}

export default ManageLocationDrawer;

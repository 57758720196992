import React, { useContext, useEffect, useState } from 'react'

import { useTheme } from '@emotion/react';
import { FileOpenOutlined, FirstPage, FmdGoodTwoTone, KeyboardArrowLeft, KeyboardArrowRight, LastPage, LocationOn, MoreVertRounded, SearchOffOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, IconButton, Input, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AuthContext from '../../context/AuthContext';

import MapIcon from "../../assets/img/company/location_icon.png";
import CouponIcon from "../../assets/img/company/coupons_icon.png";
import ModalContext from '../../context/ModalContext';
import DrawerContext from '../../context/DrawerContext';
import DescriptionIcon from '@mui/icons-material/Description';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { useCouponContext } from '../../context/CouponContext';
import CustomButton from '../../common/CustomButton';
import TextInput from '../../common/TextInput';
import { useCouponContextV2 } from '../../context/CouponContextV2';



function TablePaginationActions(props) {
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState([])
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };




  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}



export default function Coupons() {

  const { editCouponState } = useCouponContext();
  const { editCouponState: editCoupon } = useCouponContextV2();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showMenu, setShowMenu] = useState(null);

  const [companiesData, setCompaniesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState(companiesData);
  const navigate = useNavigate();

  const [selectedCoupon, setSelectedCoupon] = useState();

  

  


  const [filterValue, setFilterValue] = useState('');
  const [searchInput, setSearchInput] = useState('');



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayedData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const modalCtx = useContext(ModalContext);

  const drawerCtx = useContext(DrawerContext);

  const authCtx = useContext(AuthContext);

  const handleStatus = (status) => {
    // switch (status) {
    //   case 'active':
    //     return <div className='status-active'>Active</div>
    //     break;

    //   case 'invite_sent':
    //     return <div className='status-invite'>Invite Sent</div>
    //     break;

    //   default:
    //     break;
    // }
    if (status == "pending") {
      return <div class='status-pending'>Pending</div>;
    } else if (status == "invite_sent") {
      return <div className='status-invite'>Invite Sent</div>;
    } else if (status == "active") {
      return <div className='status-active'>Active</div>;
    } else if (status == "suspended") {
      return <div class='status-suspended'>Suspended</div>;
    }
  }



  const getAllCompanies = () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setLoading(false);
          console.log(data?.data, "this is the data");
          setCompaniesData(data?.data);

        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)
      });

  }


  useEffect(() => {
    getAllCompanies();
    getAllCategories();
  }, [])


  const handleModal = (modal, data) => {
    handleClose();
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {
    const filteredData = companiesData.filter((data) => (
      data.companyDetails.company.toLowerCase().includes(searchInput.toLowerCase()) || (data.companyDetails && data.companyDetails.keywords && data.companyDetails.keywords.toLowerCase().includes(searchInput.toLowerCase())))
    );
    setDisplayedData(filteredData);
  }, [filterValue, searchInput, loading])





  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  }

  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const open2 = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };


  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);

    setFilterValue(value)

  };

  const handleEditCoupon = () => {

    try {

      // fetch(`${process.env.REACT_APP_API_URI}`, { method: 'GET', headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      // }).then((res) => { return res.json() }).then(data => {
      //   if (data.status == 'success') {
      //     editCouponState(data.data);
      //     navigate("/create-coupons")
      //   }
      // })

      // const couponState = {
      //   selectedCompany: selectedCompany,
      //   coupon: selectedCoupon
      // }

      editCoupon(selectedCompany, selectedCoupon);
      navigate("/create-coupons");
      
      // editCouponState(couponState);
      // navigate("/create-coupons");
    } catch (e) {
      console.error(e);
    }

  }

  const couponCountStyle = (count) => {
    if(count > 0) {
      return "coupon-count coupon-count-more";
    }
    return "coupon-count coupon-count-none";
  }

  // Filter

  //Category
  const [categories, setCategories] = useState([]);
  const getAllCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/categories`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + authCtx.token,
        },
      });
  
      const data = await response.json();
      console.log(data);
  
      if (data.status === 'success') {
        setCategories(data?.data);
        console.log(data?.data);
      }
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const [filterChips, setFilterChips] = useState([]);

  const openFilterByCategoryModal = async () => {
    handleCloseFilter('status');
    modalCtx.setDetails('filter-coupon-by-category-modal', { 
      handleDisplayedData, 
      data: { categories }
    });
    modalCtx.openModal();
  }
  const handleDisplayedData = (newData) => {

    const filteredData = companiesData.filter(company =>
      company.coupons.some(coupon => newData.includes(coupon.id))
    );

    console.log("Filtered Data:", filteredData);
    setDisplayedData(filteredData);

    let selectedFilter = "Category";

    setFilterChips((prevChips) => {
      if (!prevChips.includes(selectedFilter)) {
        return [...prevChips, selectedFilter]; 
      }
      return prevChips;
    });

  };

  const handleRemoveChip = (chip) => {
    setFilterChips((prevChips) => prevChips.filter((item) => item !== chip));
    console.info(`Removed chip: ${chip}`);
    setDisplayedData(companiesData);
  };


  return (
    <>
      <div className="coupons-container">
        <div className='header-section'>
          <span className='title'>COUPONS</span>
          <div className='header-items-div'>
            <TextInput value={searchInput} placeholder='Search' onChange={(e) => setSearchInput(e.target.value)} />
            <div className='filter-field'>
              {filterChips.length > 0 && (
                <Stack direction="row" spacing={1} style={{width: '100%'}}>
                  {filterChips.map((chip, index) => (
                    <Chip 
                      key={index} 
                      label={chip} 
                      onDelete={() =>handleRemoveChip(chip)} 
                      style={{width: '100%'}}
                    />
                  ))}
                </Stack>
              )}
              <CustomButton onClick={handleClickFilter} className={'deliva-btn-filter'} >
                Filter By
              </CustomButton>
              
                <Menu id="basic-menu" anchorEl={anchorElFilter} open={open2} onClose={() => handleCloseFilter('')}>
                  <MenuItem onClick={() => handleCloseFilter('agent')}>Agent</MenuItem>
                  <MenuItem onClick={() => openFilterByCategoryModal() }>Category</MenuItem>
                </Menu>
            </div>
            <CustomButton onClick={() => { navigate('/create-coupons'); }}  >+ Add Coupon</CustomButton>
          </div>
        </div>
        <TableContainer component={Paper} className='coupon-table'>

          <Table sx={{ minWidth: 500 }} stickyHeader>

            <TableHead>
              <TableRow className='company-table-head'>
                <TableCell align="left" className='company-head-cell'>&nbsp;</TableCell>
                <TableCell align="left" className='company-head-cell'>Company</TableCell>
                <TableCell align="left" className='company-head-cell'>Deal</TableCell>
                <TableCell align="left" className='company-head-cell'>Description</TableCell>
                <TableCell align="left" className='company-head-cell'>Valid For</TableCell>
                <TableCell align="left" className='company-head-cell'>Locations</TableCell>
                <TableCell align="left" className='company-head-cell'>Status</TableCell>
                <TableCell align="right" className='company-head-cell'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='company-table-body'>
              {loading ? 
                   <TableRow>
                      <TableCell colSpan={8} align="center" style={{ border: "0px" }}>
                       <CircularProgress size={52} style={{ color: "#e84118",border: "0px" }} />
                    </TableCell>
                  </TableRow>                     
              : (rowsPerPage > 0
                ? displayedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : displayedData
              ).map((data, index) => (

                <>

                  <TableRow key={data.id} className='company-table-row' style={{ backgroundColor: 'white' }}>
                    <TableCell className='company-table-cell' style={{ width: '50px', padding: 0, paddingLeft: '1rem' }} align="left">
                      <img style={{ height: '32px', width: '32px', borderRadius: '100%' }} src={data.logo}></img>
                    </TableCell>
                    <TableCell className='company-table-cell' style={{ width: '150px' }} align="left">{ data.companyDetails.company }</TableCell>
                    <TableCell className='company-table-cell' style={{ width: '150px' }} align="left">
                      <div className={ couponCountStyle(data.coupons.length) }>
                        <img className='coupon-count-img' src={CouponIcon}></img>
                        <span>{data.coupons.length} Coupon{data.coupons.length > 1 && "s"}</span>
                      </div>
                    </TableCell>
                    <TableCell className='company-table-cell' style={{ width: '200px' }} align="left"></TableCell>
                    <TableCell className='company-table-cell' style={{ width: '100px' }} align="left"></TableCell>
                    <TableCell className='company-table-cell' style={{ width: '100px' }} align="left"></TableCell>
                    <TableCell className='company-table-cell' style={{ width: '140px' }} align="left"></TableCell>
                    <TableCell className='company-table-cell' style={{ width: '50px' }} align="left">
                      <div className='icons-container'>
                        <ArrowDropDownIcon
                          style={{ rotate: showMenu == data.id + "hidden" ? '180deg' : '0deg' }} aria-controls={open ? 'fade-menu' : undefined}
                          aria-haspopup="true" null aria-expanded={open ? 'true' : undefined} onClick={(e) => {
                            if (showMenu == data.id + "hidden") {
                              setShowMenu(null);
                            } else {
                              setShowMenu(data.id + "hidden");
                            }
                        }} />
                      </div>
                    </TableCell>
                  </TableRow>

                  {
                    showMenu == data.id + "hidden" ?  <> {
                      data.coupons?.map((item) => <TableRow key={item.id + "hidden"} className='company-table-row hidden' style={{ backgroundColor: '#fafafc'}}>
                        <TableCell className='company-table-cell' style={{ width: '50px', padding: 0, paddingLeft: '1rem' }} align="left">
                          <img style={{ height: '32px', width: '32px', borderRadius: '100%' }} src={data.logo}></img>
                        </TableCell>
                        <TableCell className='company-table-cell' style={{ flex: 1 }} align="left">{/* {data.companyDetails.company} */}</TableCell>
                        <TableCell className='company-table-cell' style={{ width: '150px' }} align="left">
                          <span><strong>10% Off</strong><br /> Register</span>
                        </TableCell>
                        <TableCell className='company-table-cell' style={{ width: '200px' }} align="left">{ item.description }</TableCell>
                        <TableCell className='company-table-cell' style={{ width: '100px' }} align="left">No Expiry</TableCell>
                        <TableCell className='company-table-cell' style={{ width: '100px' }} align="left">                          
                          { item.onlineOnly == "yes" && <div className='couponDiv'>
                            {/* <img className='mapIcon' src={MapIcon}></img> */}
                            <span>Online</span>
                          </div> }
                        </TableCell>
                        <TableCell className='company-table-cell' style={{ width: '140px' }} align="left">
                          <div className='status-container'>{handleStatus(data.companyDetails.status)}</div>
                        </TableCell>
                        <TableCell className='company-table-cell' style={{ width: '50px' }} align="left">
                          <div className='icons-container'>
                            {/* <DescriptionIcon/> */}
                            <MoreVertRounded aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" null aria-expanded={open ? 'true' : undefined} onClick={(e) => {
                              setSelectedCompany(data);
                              setSelectedCoupon(item)
                              handleClick(e);
                            }} />
                          </div>
                        </TableCell>
                      </TableRow>)}
                    </> : null }
                  </>
                )
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} className='agent-table-pagination'>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={displayedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Menu
        id={`fade-menu$`}
        MenuListProps={{
          'aria-labelledby': `fade-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handleModal('assign-agent-register', "id")}>Assign to Agent</MenuItem>
        <MenuItem onClick={() => {
          // toggleDrawerHandler('manage-locations', null);

          handleEditCoupon();

          handleClose()
        }}>Edit Coupon</MenuItem>
        <MenuItem onClick={() => {
          toggleDrawerHandler('manage-company', selectedCompany);
          handleClose()
        }}>Preview</MenuItem>
        <MenuItem onClick={() => handleModal('delete-coupon', "id")}>Delete Coupoun</MenuItem>
      </Menu>
    </>
  );
}

//react
import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
//mui
import { Button, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, Box, Tabs, Tab, Typography, TextareaAutosize } from '@mui/material';


//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
import TextInput from '../../../common/TextInput'
import "../../../Pages/Companies/Companies.scss";

const ManageCompanyDrawer = ({drawerDetails}) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [statusMessage, setStatusMessage] = useState('');
  const [editorContent, setEditorContent] = useState('');

  console.log(drawerDetails, "this is data from model");
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const updateCompanyDetails = async () => {
    setLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/companies/${companyObj.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body: JSON.stringify(companyObj),
        });
        const data = await response.json();
        if (data.status === 'success') {
            drawerCtx.closeDrawer();
        } else {
            console.error(data.message);
        }
    } catch (error) {
        console.error('Error updating company:', error);
    } finally {
        setLoading(false);
    }
  };
  const addNote = async () => {
    if (!editorContent.trim()) return;
  
    setLoading(true);
    setStatusMessage('Saving note... Please wait');
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v3/companies/${drawerDetails.id}/notes`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
          body: JSON.stringify({ note: editorContent }),
        }
      );
  
      const data = await response.json();
      if (data.status === 'success') {
        setNotes([...notes, { id: data.note.id, content: editorContent }]);
        setEditorContent('');
        setStatusMessage('Note successfully added');
      } else {
        setStatusMessage('Error adding note. Please try again.');
      }
    } catch (error) {
      console.error('Error adding note:', error);
      setStatusMessage('Error adding note. Please try again.');
    } finally {
      setLoading(false);
      setTimeout(() => setStatusMessage(''), 3000);
    }
  };
  
  const deleteNote = async (index) => {
    const noteToDelete = notes[index];
    setLoading(true);
    setStatusMessage('Deleting note... Please wait');
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v3/companies/${drawerDetails.id}/notes/${noteToDelete.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authCtx.token}`,
          },
        }
      );
  
      if (response.ok) {
        setNotes(notes.filter((_, i) => i !== index)); // Remove the note from the list
        setStatusMessage('Note successfully deleted');
      } else {
        setStatusMessage('Error deleting note. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting note:', error);
      setStatusMessage('Error deleting note. Please try again.');
    } finally {
      setLoading(false);
      setTimeout(() => setStatusMessage(''), 3000);
    }
  };
  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  const [loading, setLoading] = useState(false)

  const [notes, setNotes] = useState([]);
  const [createBlogObj, setCreateBlogObj] = useState({ snippet: "", title: "", post: "", pageTitle: "", metaDescription: "", metaKeywords: "", isVideo: "no", videoUrl: "" });
  const [companyObj, setCompanyObj] = useState({
    id: drawerDetails.id,
    logo: drawerDetails.logo,
    companyDetails: {
        id: drawerDetails.companyDetails.id,
        orderNo: drawerDetails.companyDetails.orderNo,
        company: drawerDetails.companyDetails.company,
        contact: drawerDetails.companyDetails.contact,
        email: drawerDetails.companyDetails.email,
        mobile: drawerDetails.companyDetails.mobile,
        colour: drawerDetails.companyDetails.colour,
        status: drawerDetails.companyDetails.status,
        slug: drawerDetails.companyDetails.slug,
        keywords: drawerDetails.companyDetails.keywords,
        tasksCompleted: drawerDetails.companyDetails.tasksCompleted,
        lastTaskCompletedDate: drawerDetails.companyDetails.lastTaskCompletedDate,
    },
    contactDetails: {
        contact: drawerDetails.contactDetails.contact,
        email: drawerDetails.contactDetails.email,
        mobile: drawerDetails.contactDetails.mobile,
        status: drawerDetails.contactDetails.status,
    },
    locations: [],
    coupons: 1,
    agents: [],
    globalSupplier: true,
    countries: null,
});



  const submitCreateBlog = () => {

    setLoading(true)

    const { snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          getAllBlogs(data.id)
        }
      })

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const getAllBlogs = (id) => {

    try {

      fetch(`${process.env.REACT_APP_API_URI}/v3/blogs/${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            setLoading(false)
            openUploadImageModal(id, data.data[0])
            drawerCtx.setDetails(null, data.data[0]);
            drawerCtx.closeDrawer();
          }
        }).catch(err => {
          console.error(err)
        });

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: id, blog: blog });
    modalCtx.openModal();
  }

  const [value, setValue] = useState('contacts');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Manage Company</h1>
        </header>
        <section style={{display:'flex', flexDirection:'column' , gap:'1rem' }}>
          <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary" aria-label="Manage Company Tabs">
            <Tab value="contacts" label="Contacts" />
            <Tab value="branding" label="Branding" />
            <Tab value="notes" label="Notes" />
          </Tabs>
          {value === "contacts" && (
            <div className="tab-content">
              <TextField label="Company Name" fullWidth margin="normal" value={companyObj.companyDetails.company || ""}
                onChange={(e) =>
                  setCompanyObj((prev) => ({
                    ...prev,
                    companyDetails: { ...prev.companyDetails, company: e.target.value },
                  }))
                }
              />
              <TextField label="Slug" fullWidth margin="normal" defaultValue={drawerDetails?.companyDetails?.slug} />
              <TextField label="Contact Person" fullWidth margin="normal" value={companyObj.companyDetails.contact || ""} 
                onChange={(e) =>
                  setCompanyObj((prev) => ({
                    ...prev,
                    companyDetails: { ...prev.companyDetails, contact: e.target.value },
                  }))
                }
              />
              <TextField label="Email Address" fullWidth margin="normal" defaultValue={drawerDetails?.companyDetails?.email}/>
              <TextField label="Mobile Number" fullWidth margin="normal" defaultValue={drawerDetails?.companyDetails?.mobile}/>
              <Typography variant="body2" className="note-text">NOTE: You can select 'Global' if this is a digital service which can be provided to any country.</Typography>
              <TextField label="Countries of Operations" fullWidth margin="normal" placeholder="Select countries"/>
              <TextareaAutosize label="Description" className="text-area" placeholder="Description"minRows={4}/>
              <TextField label="Keywords"fullWidth margin="normal" defaultValue={drawerDetails?.companyDetails?.keywords}/>
              <div className="button-container">
                <Button variant="outlined" onClick={() => drawerCtx.closeDrawer()}>Cancel</Button>
                <Button variant="contained" onClick={updateCompanyDetails} disabled={loading}>{loading ? <CircularProgress size={22} /> : "Save Changes"}</Button>
              </div>
            </div>
          )}
          {value === "branding" && (
            <div className="branding-tab-content">
              <div className="branding-logo-placeholder">
                {drawerDetails?.logo ? (
                  <img
                    src={drawerDetails.logo}
                    alt="Company Logo"
                  />
                ) : (
                  <img
                    src="/src/assets/img/placeholder.png"
                    alt="Placeholder"
                  />
                )}
              </div>
              <p className="branding-note">(Note: This will be used as a circle logo in all instances. Please upload a square image 100x100)</p>
              <div className="branding-file-upload">
                <input type="file" id="fileInput" style={{ display: "none" }} onChange={(e) => console.log("File selected:", e.target.files[0])}/>
                <Button variant="outlined" onClick={() => document.getElementById("fileInput").click()}>Choose File</Button>
                <span>No file chosen</span>
              </div>
              <TextField label="Colour" variant="outlined" fullWidth className="branding-color-input"/>
              {/* Buttons */}
              <div className="branding-buttons">
                <Button variant="outlined" onClick={() => drawerCtx.closeDrawer()} className="branding-cancel-button">Cancel</Button>
                <Button variant="contained" className="branding-update-button">Update</Button>
              </div>
            </div>
          )}
          {value === 'notes' && (
            <div className="notes-container">
            <ReactQuill value={editorContent} onChange={setEditorContent} modules={quillModules} placeholder="Insert text here..." className="quill-editor" />
            <Button className="add-note-button" onClick={addNote} disabled={!editorContent.trim() || loading}>{loading ? <CircularProgress size={20} /> : 'Add Note'}</Button>
            <div className="notes-list">
              {notes.length > 0 ? (
                notes.map((note, index) => (
                  <div key={index} className="note-item">
                    <div
                      dangerouslySetInnerHTML={{ __html: note }}
                      className="note-content"
                    />
                    <CloseIcon
                      className="remove-note-icon"
                      onClick={() => deleteNote(index)}
                    />
                  </div>
                ))
              ) : (
                <div className="no-notes">You have no notes yet</div>
              )}
            </div>
            {statusMessage && <div className="status-message">{statusMessage}</div>}
          </div>
          )}        
          {/* <div className="double-column-form aligned-left margin-top">
            <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Cancel</Button>
            <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Save Changes</Button>
            </div> */}
        </section>
      </div>
    </div>
  )
}

export default ManageCompanyDrawer;


import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

const SaveLocationModal = ({ modalDetails }) => {

    console.log(modalDetails);
    const { handleSaveLocation } = modalDetails;
   


  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const closeHandle = () => {
    modalCtx.closeModal();
  }
  const handleYes = () => {
      modalCtx.closeModal();
      handleSaveLocation();
  }
    
  return (
    <>
      <div className="create-devotional-container">
        <div className='devotional-header'>
          <h1>Save Changes</h1>
          {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
        </div>
        <div className=" body-container create-container" style={{textAlign:"center"}}>
          <span>Are you sure you want to save this Changes ?</span>
        </div>
        <div className="footer-devotional">
        <Button style={{color:"#555"}} onClick={() => closeHandle()}>No</Button>
        <Button style={{color:"#555"}} onClick={handleYes}>Yes</Button>
        </div>
      </div>
    </>
  )
}

export default SaveLocationModal;

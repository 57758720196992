import { useContext } from 'react';
import { Modal, Box } from '@mui/material';

import ModalContext from '../context/ModalContext.js';

// import CreateCategoriesModal from '../utils/Modals/Categories/CreateCategoriesModal.jsx';
import EditBlogAuthorModal from '../utils/Modals/Blogs/EditBlogAuthorModal.jsx';
import EditBlogTopicsModal from '../utils/Modals/Blogs/EditBlogTopicsModal.jsx';
import EditBlogTagsModal from '../utils/Modals/Blogs/EditBlogTagsModal.jsx';
import DeleteBlogModal from '../utils/Modals/Blogs/DeleteBlogModal.jsx';
import PublishBlogModal from '../utils/Modals/Blogs/PublishBlogModal.jsx';
import UploadFixedImageModal from '../utils/Modals/ImageCropper/UploadFixedImageModal'
import DeleteTagModal from '../utils/Modals/Tags-Topics-Author/DeleteTagModal.jsx';
import DeleteTopicModal from '../utils/Modals/Tags-Topics-Author/DeleteTopicModal.jsx';
import DeleteCompanyModal from '../utils/Modals/Companies/DeleteCompanyModal.jsx';
import DeleteCompanyManageLocation from '../utils/Modals/Companies/ManageLocationDeleteModal.jsx';
import SaveCompanyManageLocation from '../utils/Modals/Companies/ManageLocationSaveModal.jsx';
import AddCompanyManageLocation from "../utils/Modals/Companies/ManageLocationAddLocationModal.jsx"
import AssignAgentModal from '../utils/Modals/Companies/AssignAgentModal.jsx';
import DeleteServiceModal from '../utils/Modals/ERServices/DeleteServiceModal.jsx';
import AssignAgentCouponModal from '../utils/Modals/Companies/AssignAgentModal.jsx';
import DeleteCouponModal from '../utils/Modals/Coupons/DeleteCompanyModal.jsx';
import CreateCouponModal from '../utils/Modals/Create-Coupons/CreateCouponModal.jsx';
import CouponCreatedModal from '../utils/Modals/Create-Coupons/CouponCreatedModal.jsx';
import AddLocationModal from '../utils/Modals/Create-Coupons/AddLocationModal.jsx';
import UploadCompanyLogo from '../utils/Modals/Companies/UploadCompanyLogo.jsx';
import FilterCouponByCategoryModal from "../utils/Modals/Coupons/FilterCouponByCategoryModal.jsx"

const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
    padding: '0px',
    // borderRadius: '10px',
    overflow:'hidden'
  };

  const currentModal = () => {
    switch (modalCtx.modal) {

      // case "create-categories": return <Modal open={modalCtx.open} className="modal-root"><Box sx={boxStyles}><CreateCategoriesModal modalDetails={modalCtx.details} /></Box></Modal>;

      // file Upload
      case "upload-fixed-image": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadFixedImageModal modalDetails={modalCtx.details} /></Box></Modal>;

      // blogs
      case "confirm-publish-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
      
     //Companies Pages
     case "delete-companies": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCompanyModal modalDetails={modalCtx.details} /></Box></Modal>;
     case "assign-agent": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AssignAgentModal modalDetails={modalCtx.details} /></Box></Modal>;
     case "upload-company-logo": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadCompanyLogo modalDetails={modalCtx.details} /></Box></Modal>;
     case "delete-company-manage-location": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCompanyManageLocation modalDetails={modalCtx.details} /></Box></Modal>;
     case "add-company-manage-location": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddCompanyManageLocation modalDetails={modalCtx.details} /></Box></Modal>;
     case "save-company-manage-location": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><SaveCompanyManageLocation modalDetails={modalCtx.details} /></Box></Modal>;

     //ER Services
     case "delete-service": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteServiceModal modalDetails={modalCtx.details} /></Box></Modal>;


      //tags and topics
      case "delete-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTagModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "delete-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTopicModal modalDetails={modalCtx.details} /></Box></Modal>;

      //Coupon
      case "assign-agent-register": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AssignAgentCouponModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "delete-coupon": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCouponModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "filter-coupon-by-category-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><FilterCouponByCategoryModal modalDetails={modalCtx.details} /></Box></Modal>;

      // Create-Coupon
      case "create-coupon-accept": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreateCouponModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "coupon-created": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CouponCreatedModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "add-location-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddLocationModal modalDetails={modalCtx.details} /></Box></Modal>;


      default: return null;

    }
  }

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;


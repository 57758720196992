import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

const DeleteServiceModal = () => {


  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("network");

  const handleDelete = () => {
    const id = modalCtx.details;

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authCtx.token}`, 
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          console.log('Service deleted successfully:', id);
          modalCtx.setPageUpdate({ page: 'ERServices', removedServiceId: id });
          modalCtx.closeModal();
        } else {
          console.error('Failed to delete service:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error deleting service:', error);
      });
  };

  const closeHandle = () => {
    modalCtx.closeModal();
  }

  return (
    <>
      <div className="create-devotional-container">
        <div className='devotional-header'>
          <h1>Delete Service</h1>
          {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
        </div>
        <div className=" body-container create-container">
          <span>Are you sure you want to delete this ER Service ?</span>
        </div>
        <div className="footer-devotional">
        <Button style={{color:"#555"}} onClick={() => closeHandle()}>No</Button>
        <Button style={{color:"#555"}} onClick={() => handleDelete()}>Yes</Button>
        </div>
      </div>
    </>
  )
}

export default DeleteServiceModal;

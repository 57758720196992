//react
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';



//mui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, Tabs, Tab, RadioGroup, FormLabel, Radio, Checkbox, Autocomplete, createFilterOptions, Chip } from "@mui/material";

import {
  Map,
  useApiIsLoaded,
  useMapsLibrary,
  AdvancedMarker,
  Pin
} from "@vis.gl/react-google-maps";

//context
import AuthContext from "../../../context/AuthContext";
import DrawerContext from "../../../context/DrawerContext";
import ModalContext from "../../../context/ModalContext";
import { generateTempLogo } from '../../../Pages/ERServices/ERServices.jsx';

//component
import TextInput from "../../../common/TextInput";
import { set } from "firebase/database";
const filter = createFilterOptions();

const CreateEmergencyServiceDrawer = ({drawerDetails}) => {

  const { comapniesNameData, type } =  drawerDetails;

  const Details = comapniesNameData || [];

  const [local, setLocal]=useState(type=="local");

  const [Countries,setCountries]=useState([]);
  const [countryValue, setCountryValue] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  // console.log(Details, "this is our drawer details");
  
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [checkboxState, setCheckBoxState] = React.useState({
    phoneCheckbox: true,
    navigateCheckbox: false,
  });
  const {  phoneCheckbox, navigateCheckbox } = checkboxState;

  const [isCreating, setisCreating] = useState(false);
  const [allowLocation, setAllowLocation] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [companyValue, setCompanyValue] = React.useState(null);
  const [erServiceTitleValue, setERServiceTitleValue] = React.useState(null);

  const [value, setValue] = useState("details");
  const [keywords, setKeywords] = useState();

  const [locationValue, setLocationValues] = useState(null);

  const [originalCompaniesList, setOriginalCompaniesList] = useState(null);
  const [loc, setLoc] = useState({address: "South Africa", position: undefined});
  const geocoding = useMapsLibrary("geocoding");
  const isLoaded = useApiIsLoaded();

  const [loading, setLoading] = useState(false);
  const [isDetailsValid, setIsDetailsValid] = useState(false);
  const [state, setState] = useState("");
  const [createEmergencyServiceObj, setEmergencyServiceObj] = useState({
    title: "",
    company: "",
    priority: "",
    phone: "",
    description: "",
    keywords: "no",
    locations: [],
  });

  const [categoryValue, setCategoryValue] = React.useState('national');

  const emergencyContacts = [
    { Category: 'National Emergency Number', title: 'NATIONAL EMERGENCY HOTLINE', Priority: 1, type: 'police' },
    { Category: 'Ambulance Service (Public)', title: 'PUBLIC AMBULANCE', Priority: 5, type: 'medical' },
    { Category: 'Ambulance Service (Private)', title: 'PRIVATE AMBULANCE (PAID)', Priority: 6, type: 'medical' },
    { Category: 'Animal Hospital (or Veterinarian)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Air Ambulance', title: 'AIR AMBULANCE', Priority: 7, type: 'medical' },
    { Category: 'Disaster Management', title: 'DISASTER MANAGEMENT', Priority: 15, type: 'fire' },
    { Category: 'Fire Brigade', title: 'FIRE FIGHTERS', Priority: 4, type: 'fire' },
    { Category: 'Helpline (Childline)', title: 'CHILDREN’S HELPLINE', Priority: 20, type: 'police' },
    { Category: 'Helpline (Rapeline)', title: 'RAPE ASSISTANCE HELPLINE', Priority: 21, type: 'police' },
    { Category: 'Helpline (Depression & Anxiety)', title: 'DEPRESSION & ANXIETY HELPLINE', Priority: 22, type: 'medical' },
    { Category: 'Helpline (Suicide Prevention)', title: 'SUICIDE PREVENTION HELPLINE', Priority: 23, type: 'medical' },
    { Category: 'Helpline (Poison)', title: 'POISON HELPLINE', Priority: 24, type: 'medical' },
    { Category: 'Hospital (Public)', title: 'PUBLIC HOSPITAL', Priority: 8, type: 'medical' },
    { Category: 'Hospital (Private)', title: 'PRIVATE HOSPITAL (PAID)', Priority: 9, type: 'medical' },
    { Category: 'Hospital (Military)', title: 'MILITARY HOSPITAL (PAID)', Priority: 10, type: 'medical' },
    { Category: 'Humanitarian Organization', title: 'HUMANITARIAN RELIEF', Priority: 33, type: 'shelters' },
    { Category: 'Municipal Emergency Number', title: 'MUNICIPAL EMERGENCIES', Priority: 34, type: 'fire' },
    { Category: 'Neighborhood Watch', title: 'NEIGHBORHOOD WATCH', Priority: 28, type: 'police' },
    { Category: 'Pest Control (Baboon Control)', title: 'BABOON CONTROL', Priority: 25, type: 'pests' },
    { Category: 'Pest Control (Beekeeper)', title: 'BEEKEEPER', Priority: 26, type: 'pests' },
    { Category: 'Pest Control (Snake Handler)', title: 'SNAKE HANDLER', Priority: 27, type: 'pests' },
    { Category: 'Police Service', title: 'POLICE', Priority: 2, type: 'police' },
    { Category: 'Rescue (Mountain)', title: 'MOUNTAIN RESCUE', Priority: 12, type: 'rescue' },
    { Category: 'Rescue (Wilderness / Offroad)', title: 'OFFROAD & WILDERNESS RESCUE', Priority: 13, type: 'rescue' },
    { Category: 'Rescue (Sea)', title: 'SEA RESCUE', Priority: 14, type: 'rescue' },
    { Category: 'Rescue (Lifesavers)', title: 'LIFESAVERS', Priority: 15, type: 'rescue' },
    { Category: 'Shelter (Safehouse)', title: 'SAFEHOUSE', Priority: 17, type: 'shelters' },
    { Category: 'Shelter (Night Shelter)', title: 'NIGHT SHELTER', Priority: 18, type: 'shelters' },
    { Category: 'Tipline (Crime)', title: 'REPORT CRIME', Priority: 29, type: 'police' },
    { Category: 'Tipline (Fraud)', title: 'REPORT FRAUD', Priority: 30, type: 'police' },
    { Category: 'Tipline (Human Trafficking)', title: 'REPORT HUMAN TRAFFICKING', Priority: 31, type: 'police' },
    { Category: 'Tipline (Shark Sightings)', title: 'REPORT SHARK SIGHTINGS', Priority: 32, type: 'pests' },
    { Category: 'Traffic Department', title: 'TRAFFIC DEPARTMENT', Priority: 3, type: 'police' },
    { Category: 'Veterinarian (or Animal Hospital)', title: 'VETERINARIAN', Priority: 19, type: 'pests' },
    { Category: 'Volunteer Firefighters', title: 'VOLUNTEER FIRE FIGHTERS', Priority: 11, type: 'fire' },
  ];

  const states = [
    { id: 1, name: "Eastern Cape" },
    { id: 2, name: "Free State" },
    { id: 3, name: "Gauteng" },
    { id: 4, name: "KwaZulu-Natal" },
    { id: 5, name: "Limpopo" },
    { id: 6, name: "Mpumalanga" },
    { id: 7, name: "Northern Cape" },
    { id: 8, name: "North West" },
    { id: 9, name: "Western Cape" },
  ];
  
  
  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  useEffect(() => {
    const isValid =
      !!companyValue &&
      !!erServiceTitleValue &&
      !!createEmergencyServiceObj.phone &&
      !!createEmergencyServiceObj.description &&
      !!keywords && keywords.length > 0 &&
      (local || !!countryValue) &&
      (categoryValue !== "regional" || !!selectedState);
  
    setIsDetailsValid(isValid);
  }, [
    companyValue,
    erServiceTitleValue,
    createEmergencyServiceObj.phone,
    createEmergencyServiceObj.description,
    keywords,
    countryValue,
    categoryValue,
    selectedState,
    local,
  ]);
  
  useEffect(() => {
    async function getAddressPosition() {
      if (!isLoaded || !geocoding || !loc.address) return;
  
      try {
        const geocoder = new geocoding.Geocoder();
        const response = await geocoder.geocode({ address: loc.address });
  
        if (response.results && response.results.length > 0) {
          setLoc((prev) => ({
            ...prev,
            position: response.results[0].geometry.location,
          }));
        } else {
          console.warn("No results found for the given address.");
          setLoc((prev) => ({
            ...prev,
            position: null,
          }));
        }
      } catch (error) {
        console.error("Geocoding failed:", error);
        setLoc((prev) => ({
          ...prev,
          position: null,
        }));
      }
    }
  
    getAddressPosition();
  }, [loc.address, geocoding, isLoaded]);
  
  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (!checked && phoneCheckbox && navigateCheckbox) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (checked) {
      setCheckBoxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (!checked && !phoneCheckbox && !navigateCheckbox) {
      return;
    }
  };

  const createEmergencyService = () => {
    if (isCreateDisabled) return;
      setisCreating(true);

      const { phone, description, locations } = createEmergencyServiceObj;
      const emergencyService = emergencyContacts.find((contact)=> contact.title === erServiceTitleValue?.title);
      const title = emergencyService.title;
      const priority = emergencyService.Priority;
      const erService = emergencyService.type;

      if(categoryValue == "regional") {
        countryValue.state = state;
      }

      if (!emergencyService) {
        console.error("Emergency Service not found!");
        setisCreating(false);
        return;
      }
    const payload = {
      erType: type,
      priority: emergencyService.Priority,
      companyId: companyValue?.id,
      emergencyService: erService,
      title: title,
      description: description,
      keywords: keywords ? keywords.join("|") : "",
      phone: phone,
      phoneBtn: phoneCheckbox ? "yes" : "no",
      navigateBtn: navigateCheckbox ? "yes" : "no",
      regionalType: categoryValue,
      regionalZone: countryValue
    }
    
    console.log(payload);
    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/emergency-services`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify(payload),
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          if(type == "local") {
            setAllowLocation(true);
            setValue('location');
            setisCreating(false);
          } else {
            drawerCtx.setPageUpdate({page: "ERServices"});
            drawerCtx.closeDrawer();
            setisCreating(false);
          }
        }
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rows, setRows] = useState([]);
  const columns = [
    { field: "title", headerName: "Title", width: 150 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "longitude", headerName: "Longitude", width: 150 },
    { field: "latitude", headerName: "Latitude", width: 150 },
  ];

  const getAllCompanies = () => {    

    if(Details) {
      console.log(Details, "this is company the data drawer details");
      const companiesList = Details.map(company => ({
        id: company.id,
        title: company.companyDetails.company,
        image: company.logo
      }));

      setCompaniesData(companiesList);
      setOriginalCompaniesList(Details);
    } else {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          console.log(data?.data, "this is compnay the data");
          const companiesList = data?.data.map(company => ({
            id: company.id,
            title: company.companyDetails.company
          }));

          setCompaniesData(companiesList);
          setOriginalCompaniesList(data?.data);

        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)
      });
    }

  }

  const getAllCountries= ()=>{
    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/countries`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status === 'success') {
        setCountries(
          data.data.map((country) => ({
            ...country,
            regions: country.regions || [],
          }))
        );
      }
    }).catch(err => {
      console.error(err)
    });
  }
  
  useEffect(()=>{
    getAllCompanies();
    getAllCountries();
  },[])

  useEffect(()=>{
    if(type == "local") {
      const locationData = originalCompaniesList?.find((company)=> company.companyDetails.company === companyValue?.title)?.locations;
      // console.log(locationData, "this is our location data");
      setLocationValues(locationData || []);
    }
  },[companyValue])

  useEffect(() => {
    let  columns = [
      { field: 'title', headerName: 'Title', width: 150 },
      { field: 'address', headerName: 'Address', flex: 1 },
      { field: 'longitude', headerName: 'Longitude', width: 150 },
      { field: 'latitude', headerName: 'Latitude', width: 150 },
    ];
    
    // Use a default empty array for safety
    const rows = (locationValue || []).map((location) => ({
      id: location.id,
      title: location.title,
      address: location.address,
      longitude: location.longitude,
      latitude: location.latitude,
    }));

    setRows(rows || []);
  }, [locationValue]);

  const isCreateDisabled = () => {
    if(type == "local" && (!companyValue || !erServiceTitleValue || !createEmergencyServiceObj.phone || isCreating)) {
      return true;
    } else if(type != "local" && (!companyValue || !erServiceTitleValue || !createEmergencyServiceObj.phone || isCreating || !countryValue || (categoryValue == "regional" && !state))) {
      return true;
    }
    return false
  }

  return (
    <div className="drawer-container">
      <div className="drawer">
      <header><h1 data-aos="fade-right" data-aos-delay="500">{drawerDetails.type === "local" ? "Create Local Emergency Service" : "Create Regional Emergency Service"}</h1></header>
        <section style={{ padding: "0rem", display: "flex", flexDirection: "column", gap: "2rem", }}>
          <Tabs value={value} onChange={handleChange} textColor="black" indicatorColor="primary" style={{ borderBottom: "1px solid #ccc" }} aria-label="secondary tabs example" >
            <Tab className="tabs-card" value="details" label="DETAILS" />
            <Tab className="tabs-card" value="location" label="LOCATIONS" disabled={!isDetailsValid} />
          </Tabs>
          <div style={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "2rem",}}>
            {
              value === "details" ? (
                <>
                  <Autocomplete className="full-width-column" value={companyValue || null}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      if (newValue) {
                        setCompanyValue(newValue || null);
                      } else {
                        setCompanyValue(null);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      return options.filter((option) =>
                        option.title.toLowerCase().includes(inputValue.toLowerCase())
                      );
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="company-list"
                    options={companiesData}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option?.title || null;
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id || option.title} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {option.image ? (
                          <img src={option.image} alt="Company Logo" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                        ) : (
                          generateTempLogo(option.title)
                        )}
                        <span>{option.title}</span>
                      </li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField className="full-width-column" fullWidth {...params} label="Company" />
                    )}
                    InputProps={{
                      style: {
                        padding: "0",      
                        height: "56px",       
                        display: "flex",
                        alignItems: "center",  
                        boxSizing: "border-box", 
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "0 16px",  
                        height: "100%",  
                        lineHeight: "1.5", 
                      },
                    }}
                    style={{
                      marginBottom: "16px",      
                    }}
                    InputLabelProps={{
                      style: {
                        transform: "translate(0, 10px) scale(1)", 
                      },
                    }}
                  />
                  <Autocomplete
                    className="full-width-column"
                    value={erServiceTitleValue}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setERServiceTitleValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setERServiceTitleValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setERServiceTitleValue(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="emergency-service-list"
                    options={emergencyContacts}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderOption={(props, option ) => (
                      <li
                        {...props}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '8px 16px',
                          alignItems: 'center',
                          borderBottom: '1px solid #eee',
                          backgroundColor: props['aria-selected'] ? '#f0f0f0' : 'white',
                          cursor: 'pointer',
                        }}
                      >
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>{option.title}</span>
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#666',
                            backgroundColor: '#e0e0e0',
                            padding: '2px 8px',
                            borderRadius: '12px',
                          }}
                        >
                          Priority: {option.Priority}
                        </span>
                      </li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField className="full-width-column" fullWidth {...params} label="Emergency Service" />
                    )}
                    InputProps={{
                      style: {
                        padding: "0",             
                        height: "56px",          
                        display: "flex",
                        alignItems: "center",      
                        boxSizing: "border-box",   
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "0 16px",    
                        height: "100%",          
                        lineHeight: "1.5",    
                      },
                    }}
                    style={{
                      marginBottom: "16px",    
                    }}
                    InputLabelProps={{
                      style: {
                        transform: "translate(0, 10px) scale(1)",
                      },
                    }}
                  />
                  <div className="" style={{ width: "100%", display: "flex" }}>
                    <TextField
                      fullWidth
                      name="phone"
                      label="Default Phone Number"
                      value={createEmergencyServiceObj?.phone}
                      onChange={(e) =>
                        setEmergencyServiceObj((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }))
                      }
                      InputProps={{
                        style: {
                          padding: "0",        
                          height: "56px",   
                          display: "flex",    
                          alignItems: "center",   
                          boxSizing: "border-box", 
                        },
                      }}
                      inputProps={{
                        style: {
                          padding: "0 16px",        
                          height: "100%",            
                          lineHeight: "1.5",  
                        },
                      }}
                      style={{
                        marginBottom: "16px",       
                      }}
                    />
                  </div>
                  <TextField
                    name="description"
                    label="Description"
                    value={createEmergencyServiceObj?.description}
                    onChange={(e) =>
                      setEmergencyServiceObj((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    InputProps={{
                      style: {
                        padding: "0",             
                        height: "56px",            
                        display: "flex",          
                        alignItems: "center",     
                        boxSizing: "border-box",  
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "0 16px",        
                        height: "100%",          
                        lineHeight: "1.5",      
                      },
                    }}
                    style={{
                      marginBottom: "16px",      
                    }}
                  />
                  <Autocomplete
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    multiple
                    id="keywords-list"
                    options={[]}
                    freeSolo
                    value={keywords}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setKeywords( [...keywords, newValue] );
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setKeywords([...keywords, newValue.inputValue]);
                      } else {
                        setKeywords([...newValue]);
                        console.log("new value", newValue)
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      keywords.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                  />     
                  {
                    !local && categoryValue === "regional" ? (
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="state-select-label">State</InputLabel>
                          <Select labelId="state-select-label" id="state-select" value={state} onChange={handleStateChange} label="State" >
                          {
                            states.map((state) => (
                              <MenuItem key={state.id} value={state.name}>
                                {state.name}
                              </MenuItem>
                            ))
                          }
                          </Select>
                        </FormControl>
                      </div>
                    ) : null
                  }     
                  {
                    local && (
                      <div className="flex-drawer">
                        <FormControlLabel control={ <Checkbox checked={phoneCheckbox} onChange={handleCheckboxChange} name="phoneCheckbox" /> } label="Phone Button Visible" />
                        <FormControlLabel control={ <Checkbox checked={navigateCheckbox} onChange={handleCheckboxChange} name="navigateCheckbox" /> } label="Navigate Button Visible" />
                      </div>
                    )
                  }
                  <div className="double-column-form column-full-width  margin-top">
                    <button className="btn-primary" onClick={(e)=> drawerCtx.closeDrawer()}>Cancel</button>
                    <button disabled={isCreateDisabled()} onClick={(e) => createEmergencyService()} className="btn-primary">
                      {isCreating ? <CircularProgress size={24} style={{color: "#fff"}}/> : "Create"}
                    </button>
                    {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
                  </div>
                </>
              ) : (
                <>
                  <div style={{ position: "relative", height: "80vh" }}>
                    <div style={{ height: "calc(100% - 60px)" }}>
                      <DataGrid rows={rows} columns={columns} checkboxSelection disableSelectionOnClick pageSize={10}/>
                    </div>
                    {/* Save Button */}
                    <div style={{ position: "absolute", bottom: "4px", right: "16px", marginBottom: "0px", }}>
                    <Button style={{ backgroundColor: "red", color: "white", padding: "10px 20px", borderRadius: "4px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",}}
                      onClick={() => { console.log("Locations saved:", rows); }}
                    >
                      Save
                    </Button>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateEmergencyServiceDrawer;
import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api'; // Use LoadScriptNext
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import TextInput from '../../../common/TextInput';

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const DefaultLocation = {
  lat: 37.7749,
  lng: -122.4194,
};

const DefaultZoom = 10;

const AddAgent = () => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(DefaultLocation);
  const [agentObj, setAgentObj] = useState({ first_name: "", last_name: "", email: "", phone: "", status: "", location: "" , coords:""});
  const [originalData, setOriginalData] = useState(null);
  const isEditMode = drawerCtx.details && drawerCtx.details.id;

  useEffect(() => {
    if (isEditMode) {
      const { firstName, lastName, email, phone, status, location, latitude, longitude} = drawerCtx.details;
      const originalObj = { first_name: firstName, last_name: lastName, email, phone, status, location, coords: `${latitude}, ${longitude}` };
      setAgentObj(originalObj);
      setOriginalData(originalObj);

      if (latitude && longitude) {
        const lat = parseFloat(latitude);
        const lng = parseFloat(longitude);
  
        // Update map and coordinates field
        setSelectedLocation({ lat, lng });
        setAgentObj((prev) => ({ ...prev, coords: `${lat}, ${lng}` }));
      }
    }
  }, [drawerCtx.details, isEditMode]);

  // Fetch address 
  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();
  
      if (data.status === "OK" && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        setAddress(formattedAddress);
        setAgentObj((prev) => ({ ...prev, location: formattedAddress }));
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress("Error fetching address");
    }
  };

  const handleMapClick = async(e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setSelectedLocation({ lat, lng });
    fetchAddress(lat, lng);
    setAgentObj({ ...agentObj, location: `${lat}, ${lng}`, coords: `${lat}, ${lng}`});
  };

  const hasChanges = () => {
    return JSON.stringify(agentObj) !== JSON.stringify(originalData);
  };

  const submitAgent = async () => {
    if (isEditMode && !hasChanges()) {
      return;
    }

    setLoading(true);
    const { first_name, last_name, email, phone } = agentObj;
    const url = isEditMode
      ? `${process.env.REACT_APP_API_URI}/v3/admin/agent/${drawerCtx.details.id}` //Edit
      : `${process.env.REACT_APP_API_URI}/v3/admin/agent`; //Add

    const method = isEditMode ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ firstName: first_name, lastName: last_name, email, phone, location: address, latitude: selectedLocation.lat, longitude:selectedLocation.lng  })
      });
      const data = await response.json();
      if (data.status === "success") {
        setLoading(false);
        drawerCtx.closeDrawer("add-agent");
        drawerCtx.closeDrawer("edit-agent");
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1>{isEditMode ? "Edit Agent" : "Add Agent"}</h1>
        </header>
        <section style={{ padding: '1rem' }}>
          <TextInput name="first_name" label="First Name" value={agentObj.first_name} onChange={(e) => setAgentObj({ ...agentObj, first_name: e.target.value })} placeholder="First Name" />
          <TextInput name="last_name" label="Last Name" value={agentObj.last_name} onChange={(e) => setAgentObj({ ...agentObj, last_name: e.target.value })} placeholder="Last Name" />
          <TextInput name="email" label="Email" value={agentObj.email} onChange={(e) => setAgentObj({ ...agentObj, email: e.target.value })} placeholder="Email" />
          <TextInput name="phone" label="Phone" value={agentObj.phone} onChange={(e) => setAgentObj({ ...agentObj, phone: e.target.value })} placeholder="Phone" />
          <TextInput name="address" label="Address" value={agentObj.location} onChange={(e) => { setAddress(e.target.value); setAgentObj({ ...agentObj, location: e.target.value }); }} placeholder="Address" />
          <div style={{ margin: "1rem 0" }}>
            <h4>Select Location</h4>
            <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
           mapContainerStyle={mapContainerStyle}
             center={selectedLocation} 
              zoom={DefaultZoom}
              onClick={handleMapClick}
            >
             <MarkerF position={selectedLocation} />
               </GoogleMap>
            </LoadScriptNext>
          </div>
          <TextInput  name="coords"  label="Coordinates"  value={agentObj.coords}  onChange={async (e) => {   
                const coordsValue = e.target.value;
                setAgentObj((prev) => ({ ...prev, coords: coordsValue }));
                
                const [lat, lng] = coordsValue.split(",").map((coord) => parseFloat(coord.trim()));
                if (!isNaN(lat) && !isNaN(lng)) {
                  setSelectedLocation({ lat, lng });
                  
                  // Fetch and update the address field
                  const resolvedAddress = await fetchAddress(lat, lng);
                  if (resolvedAddress) {
                    setAddress(resolvedAddress);
                    setAgentObj((prev) => ({ ...prev, location: resolvedAddress }));
                  }
                }
              }}
              placeholder="Coordinates (e.g., lat, lng)" 
            />

          <div className="double-column-form aligned-right margin-top">
            <button className='btn-primary' onClick={submitAgent}>
              {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : isEditMode ? "Update" : "Add"}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddAgent;

import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const EditBlogAuthorModal = ({ modalDetails }) => {


  console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([{}]);
  const [item, setItem] = useState('');
  const [searchItem, setSearchItem] = useState('')
  const [addItemTitle, setAddItemTitle] = useState('')

  const [showItemResults, setShowItemResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  //effect
  useEffect(() => {
    getAllItems()
  }, [])

  useEffect(() => {
    setSelectedItems(modalDetails.topics)
  }, [modalDetails])

  //I had to do this effect because the state doesn't mutate immediately
  useEffect(() => {
    addNewlyCreatedItem(addItemTitle)
  }, [items, addItemTitle])

  //change visibility and get all items when searchItem is changed
  useEffect(() => {
    if (searchItem?.length > 0) {
      setShowItemResults(true)
    } else {
      setShowItemResults(false)
    }
    getAllItems()
  }, [searchItem])


  // submit item to db
  const submitEditItem = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + `/v3/admin/blog/${modalDetails.id}/topic`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          topicId: id,
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(data)
          getAllItems()
        } else {
          console.error("Error while editing devotional...");
        }
      }).catch((err) => console.error("Error while editing devotional...", err));
    }

  }

  // get all items
  const getAllItems = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/topics`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setItems(res?.data)
        setAddItemTitle(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //deleting item
  const DeleteItem = async (blogId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${blogId}/topic/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
      }
    } catch (err) {
      console.log(err);
    }
  }

  // create new item
  const submitCreateItem = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/topic`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            title,
          })
        })

      const res = await response.json();
      if (res.status == 'success') {
        getAllItems(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //this function will add the newly created item to the devotional without selecting it twice
  const addNewlyCreatedItem = (title) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].title === title) {
        submitEditItem(items[i].id)
        if (!selectedItems?.length) {
          setSelectedItems([items[i]]);
        } else {
          setSelectedItems(prev => [...prev, items[i]]);
        }
        setAddItemTitle('')
        setSearchItem('')
      }
    }
  }

  //check if item exist
  const handleCreateNewItem = (title) => {
    if (items.some(item => item?.title == title)) {
      setOpenSnackbar(true)
      return;
    } else {
      submitCreateItem(title)
    }
  }

  //add selected item to db function and array
  const handleSelectItem = (item) => {
    if (!selectedItems?.length) {
      console.log(item)
      submitEditItem(item?.id)
      setSelectedItems([item]);
      return
    }
    console.log(item)
    if (!selectedItems?.some(el => el.id === item?.id)) {
      submitEditItem(item?.id)
      setSelectedItems(prev => [...prev, item]);
    } else {
      return
    }
  }

  //filter and delete item from db and state
  const handleFilterArray = (id) => {
    DeleteItem(modalDetails.id, id)
    const newArr = selectedItems.filter((arr) => {
      return arr.id !== id
    })
    setSelectedItems(newArr)
  }

  //set search item string
  const handleSearchItem = (e) => {
    setSearchItem(e.target.value)
  }

  //handling item visibility
  const handleShowItemResult = () => {
    if (showItemResults) {
      setShowItemResults(false)
    } else {
      setShowItemResults(true)
    }
  }

  const handleClose = () => {
    if (modalDetails.onClose) {
      modalDetails.onClose(); // Execute the callback if provided
    }
    modalCtx.closeModal(); // Close the modal
  };

  // console.log(selectedItems)

  return (
    <>
      <div className="select-item-modal">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Item already exist.
          </Alert>
        </Snackbar>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Blog Topics</h1>
        </header>

        <section>
          <div className="search-item-container">
            <input type="text" placeholder='Search for item...' value={searchItem} onChange={handleSearchItem} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowItemResult()} style={showItemResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>
          <div className="items-menu" style={showItemResults ? null : { display: 'none' }} >
            <ul>
              {
                items?.filter((item) => item?.title.toLowerCase().includes(searchItem?.toLowerCase())).map((item, i) => {
                  return <li key={i} onClick={() => handleSelectItem(item)}>{item?.title}</li>
                })
              }
              {searchItem?.length == 0 ? null : <li className='add-new-item' onClick={() => handleCreateNewItem(searchItem)}><Add /> &nbsp; Add new item &nbsp; <span className='item-text'>"{searchItem}"</span></li>}
              {/* {searchItem?.length == 0 ? null : <li className='no-item'>No Item found!!</li> } */}
            </ul>
          </div>
          <div className="item-list">
            {
              selectedItems?.map((item, i) => (
                <span key={i} className='item'>{item?.title}<CloseRounded className='remove-item' onClick={() => handleFilterArray(item?.id)} /></span>
              ))
            }
          </div>
          <div className="save-changes-btn">
    <button className="btn-primary" onClick={handleClose}>
      Save Changes
    </button>
    <button className="btn-primary-border" onClick={handleClose}>
      Close
    </button>
  </div>
        </section>
      </div>
    </>
  )
}

export default EditBlogAuthorModal;

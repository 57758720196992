import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';




const UploadCompanyLogo = ({ modalDetails }) => {
  const data = modalDetails.data;
  console.log(data);
  
  const [logo, setLogo] = useState(data.logo);
  const companyID = data.id;
  

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const closeHandle = () => {
      modalCtx.closeModal();
  }


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateLogo(file);
        console.log('Image uploaded successfully:', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  async function updateLogo(file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      const response = await fetch(`https://api.delivait.com/v3/company/${companyID}/uploadLogo`, {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
        body: formData, 
      });
  
      if (!response.ok) {
        throw new Error('Failed to update Logo');
      } else {
        const result = await response.json();
        setLogo(result.imagePath);
      }
    } catch (error) {
      console.error('Error updating Logo', error);
      throw error;
    }
  }
  const generateTempLogo = (name) => {

    let initials = "";
    
    if (!name || name.trim().toUpperCase() === "N/A") {
      initials= "N/A";
    } else {
      initials=name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
    }

    return (
      <div
        style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#576574",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          fontSize: `${Math.round(100 * 0.45)}px`,
          fontWeight: "bold",
          marginBottom: "40px",
        }}
      >
        {initials}
      </div>
    );
  };
  

  return (
      <div className="create-devotional-container">
             <div className='devotional-header'>
              
               <h1>{logo ? "Update Logo" : "Add Logo"}</h1>
               {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
             </div>
                <div className=" body-container create-container">
                  <div className='upload-logo-body'>
                    <p className='heading'>Company Logo</p>
                    {data.logo ? (
                      <img style={{ height: '100px', width: '100px', borderRadius: '100%', marginBottom:"40px"}} src={logo}></img>
                    ) : (
                      generateTempLogo(data.companyDetails.company || "N/A")
                    )}
                          

                  </div>
                  <div>
                    <p style={{fontSize:"13px"}}>(NOTE: This will be used as a circle logo in all instances. Please upload a square image 100x100)</p>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    />
                    <button onClick={() => document.getElementById("fileInput").click()}>
                      Choose File
                    </button>
                  </div>
             </div>
             <div className="footer-devotional">
             <Button style={{color:"#555"}} onClick={() => closeHandle()}>Close</Button>
             </div>
           </div>
      
  )
}

export default UploadCompanyLogo;